
import blogIcon from "./images/blog_icon_foal .webP";
import { Link } from "react-router-dom"

export default function Navbar() {
  return (
    <nav className="nav">
    	<Link to="/" className="linkName">
	  <div className="site-title">
        <span class="capital">M</span>ona<span class="capital">G</span>lassfield
      </div>
		</Link>
	  <Link to="https://monaglassfield.blogspot.com/2021/12/paw-prints-lead-out.html" target="_blank">
	  	<img className="blogIcon" src={blogIcon} alt="blogIcon" />
	  </Link>
    </nav>
  )
}
